import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 数据统计相关接口

// 获取分销商业绩详情
export function getMainUserDetail(data = {}) {
  return request({
    url: '/externaladmin/reportService/misDistributorOrderDetailDailyData/list',
    method: 'post',
    data
  })
}

// 获取品类销售统计
export function getCtegoryData(data = {}) {
  return request({
    url: '/externaladmin/reportService/misDistributorProductCategoryDailyData/list',
    method: 'post',
    data
  })
}

// 产品原型分类
export function getProductCtegory(data = {}) {
  return request({
    url: '/externaladmin/productService/productCategory/list',
    method: 'post',
    data
  })
}

// 获取款式信息列表

export function getStyleList(data = {}) {
  return request({
    url: '/externaladmin/reportService/misProductStyleDailyData/list',
    method: 'post',
    data
  })
}

//图片统计模块接口

//获取图片统计-分销商列表
export function getList(data) {
  return request({
    url: '/externaladmin/reportService/misImageData/list',
    method: 'post',
    data
  })
}
