//图片统计列表-子账号
export const subAccountCols = [
  {
    prop: 'createByName',
    label: '设计师',
    minWidth: '200'
  },
  {
    prop: 'imageCounts',
    label: '上传图片数量',
    minWidth: '200',
    slotName: 'imageSlot'
    // sortable: true
  },
  {
    label: '定制产品数量',
    minWidth: '200',
    slotName: 'designedProductSlot'
    // sortable: true
  },
  {
    prop: 'counts',
    label: '出单量',
    minWidth: '200',
    slotName: 'quantitySlot'
    // sortable: true
  }
]

//图片统计列表-图片
export const imageCols = [
  {
    label: '图片/图片名称',
    minWidth: '200',
    slotName: 'productSlot'
  },
  {
    prop: 'imageCategory',
    label: '图片分类',
    minWidth: '200'
  },
  {
    prop: 'productCount',
    label: '定制产品数量',
    minWidth: '200'
    // sortable: true
  },
  {
    prop: 'orderCount',
    label: '出单数量',
    minWidth: '200'
    // sortable: true
  },
  {
    prop: 'createByName',
    label: '设计师',
    minWidth: '200'
  },
  {
    label: '上传时间',
    minWidth: '200',
    slotName: 'importTimeSlot'
  }
]

//上传图片预览
export const firstCols = [
  {
    label: '图片/图片名称',
    slotName: 'imageSlot'
  },
  {
    prop: 'imageCategory',
    label: '图片分类'
  },
  {
    prop: 'productCount',
    label: '定制产品数量'
  },
  {
    prop: 'orderCount',
    label: '出单数量'
  },
  {
    label: '上传时间',

    slotName: 'importTimeSlot'
  }
]

//定制产品详情
export const secondCols = [
  {
    label: '图片/图片名称',
    minWidth: '200',
    slotName: 'imageSlot'
  },
  {
    prop: 'imageCategory',
    label: '图片分类',
    minWidth: '200'
  },
  {
    prop: 'productCount',
    label: '定制产品数量',
    minWidth: '200'
  }
]

//出单详情
export const thirdCols = [
  {
    label: '图片/图片名称',
    minWidth: '200',
    slotName: 'imageSlot'
  },
  {
    prop: 'imageCategory',
    label: '图片分类',
    minWidth: '200'
  },
  {
    prop: 'orderCount',
    label: '出单数量',
    minWidth: '200'
  }
]
