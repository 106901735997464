var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container statistics-warpper"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.validTabs),function(ref){
var label = ref.label;
var name = ref.name;
return _c('el-tab-pane',{key:name,attrs:{"label":label,"name":name}})}),1),_c('myHeader',{ref:"myHeader",attrs:{"sup_this":_vm.sup_this,"tableData":_vm.data,"page":_vm.page,"size":_vm.size,"total":_vm.total,"isPageChange":_vm.isPageChange,"setIsPageChangeFalse":_vm.setIsPageChangeFalse,"selectionData":_vm.selectedData,"getAllData":_vm.getAllData,"getElTableInstance":_vm.getElTableInstance,"name":_vm.activeName,"query":_vm.query,"permission":_vm.curTab.permission},on:{"exportTable":_vm.exportTable}}),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total,"hideOnSinglePage":false},on:{"refreshTableEventFun":_vm.refreshTableEventFun}}),_c('CommonTable',{ref:"commonRef",attrs:{"selection":true,"tableLoading":_vm.tableLoading,"cols":_vm.cols,"infoData":_vm.data},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"imageSlot",fn:function(ref){
var row = ref.scoped;
return [_c('color-text-Btn',{on:{"click":function($event){return _vm.handleImage(row)}}},[_vm._v(_vm._s(row.imageCount)+" ")])]}},{key:"productSlot",fn:function(ref){
var row = ref.scoped;
return [_c('div',{staticClass:"flex-center"},[_c('el-popover',{attrs:{"placement":"right","trigger":"hover","visible-arrow":false}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('defaultImg',{attrs:{"src":_vm.aLiCompressPicByUrl(row.imagePath, 48)}})],1),_c('div',{staticStyle:{"height":"40px","line-height":"32px","background":"#fff"}},[_vm._v(" "+_vm._s(row.imageName)+" ")]),_c('defaultImg',{attrs:{"src":_vm.aLiCompressPicByUrl(row.imagePath, 450)}})],1),_c('span',{staticClass:"ml10"},[_vm._v(_vm._s(row.imageName))])],1)]}},{key:"designedProductSlot",fn:function(ref){
var row = ref.scoped;
return [_c('color-text-Btn',{on:{"click":function($event){return _vm.handleDesignedProduct(row)}}},[_vm._v(_vm._s(row.productCount)+" ")])]}},{key:"quantitySlot",fn:function(ref){
var row = ref.scoped;
return [_c('color-text-Btn',{on:{"click":function($event){return _vm.handleQuantity(row)}}},[_vm._v(_vm._s(row.orderCount)+" ")])]}},{key:"importTimeSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(_vm._s(_vm.parseTime(row.imageDate))+" ")]}}])}),(_vm.visible)?_c('operationDialog',{attrs:{"sup_this":_vm.sup_this,"currentData":_vm.currentData,"visible":_vm.visible,"type":_vm.type},on:{"update:visible":function($event){_vm.visible=$event}}}):_vm._e(),_c('PaginationBar',{attrs:{"hideOnSinglePage":false,"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }