<template>
  <div>
    <el-dialog
      style="margin-top: -70px"
      append-to-body
      :title="title"
      :visible.sync="visible"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <div class="dialog-container">
        <div class="time-warpper"><span class="mr10">时间:</span>{{ currentTime }}</div>
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
        <CommonTable
          :tableLoading="tableLoading"
          height="auto"
          :selection="false"
          ref="commonRef"
          :cols="cols"
          :infoData="data"
        >
          <template #imageSlot="{ scoped }">
            <div class="flex-center">
              <el-popover placement="right" trigger="hover" :visible-arrow="false">
                <div slot="reference">
                  <defaultImg :src="aLiCompressPicByUrl(scoped.imagePath, 48)"></defaultImg>
                  <!-- <el-image
                    style="width: 48px; height: 48px; border: 1px solid #dcdee0; margin-right: 25px"
                    :src="scoped.imagePath"
                    fit="contain"
                  >
                  </el-image> -->
                </div>
                <div style="height: 40px; line-height: 32px; background: #fff">
                  {{ scoped.imageName }}
                </div>

                <defaultImg :src="aLiCompressPicByUrl(scoped.imagePath, 450)"></defaultImg>
                <!-- <el-image fit="contain" :src="scoped.imagePath" style="width: 450px; height: 450px" :z-index="9999">
                </el-image> -->
              </el-popover>

              <span class="ml10">{{ scoped.imageName }}</span>
            </div>
          </template>
          <template #importTimeSlot="{ scoped }">
            {{ parseTime(scoped.imageDate) }}
          </template>
        </CommonTable>
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { firstCols, secondCols, thirdCols } from './cols'
import { parseTime } from '@/utils'
import { initDataMixin } from '@/mixins'
import { getList } from '@/api/statistics.js'
import { start } from 'nprogress'

export default {
  mixins: [initDataMixin],
  props: {
    type: {
      type: String,
      default: 'add'
    },
    visible: {
      type: Boolean,
      default: false
    },
    currentData: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cols: [],
      firstCols,
      secondCols,
      thirdCols,
      url: '/externaladmin/reportService/misImageDailyData/list',
      xMenuType: 'menu',
      mergeData: {}
    }
  },
  methods: {
    parseTime,
    handleClose() {
      this.$emit('update:visible', false)
    }
  },
  computed: {
    currentTime() {
      let time
      const { startCreateTime, endCreateTime } = this.sup_this.query
      if (this.sup_this.query.startCreateTime) {
        let startTime = ''
        let endTime = ''
        startTime =
          startCreateTime.split('-')[0] +
          '年' +
          startCreateTime.split('-')[1] +
          '月' +
          startCreateTime.split('-')[2].slice(0, 2) +
          '日'
        endTime =
          endCreateTime.split('-')[0] +
          '年' +
          endCreateTime.split('-')[1] +
          '月' +
          endCreateTime.split('-')[2].slice(0, 2) +
          '日'
        time = `${startTime} - ${endTime}`
      } else {
        let endTime = ''
        let startTime = '2021年5月1日'
        let d = new Date()
        endTime += d.getFullYear() + '年'
        endTime += d.getMonth() + 1 + '月'
        endTime += d.getDate() + '日'
        time = `${startTime} - ${endTime}`
      }
      return time
    },
    title() {
      const { startCreateTime, endCreateTime } = this.sup_this.query
      const time = {
        startCreateTime,
        endCreateTime
      }
      this.mergeData = {
        createById: this.currentData.id
      }
      if (endCreateTime) Object.assign(this.mergeData, time)
      switch (this.type) {
        case 'image':
          this.url = '/externaladmin/reportService/misImageDailyData/list'
          this.xMenuType = 'menu'
          this.cols = this.firstCols
          return '上传图片预览'
          break
        case 'designed':
          this.url = '/externaladmin/reportService/misImageProductDailyData/list'
          this.xMenuType = 'other'
          this.cols = this.secondCols
          return '定制产品详情'
          break
        case 'output':
          this.url = '/externaladmin/reportService/misImageOrderDailyData/list'
          this.xMenuType = 'other'
          this.cols = this.thirdCols
          return '出单详情'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  font-size: 14px;
  ::v-deep {
    .el-dialog__title {
      font-size: 16px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border-bottom: 1px solid #dcdee0;
    }
  }
  .dialog-container {
    max-height: 750px;
    overflow-y: auto;
    .time-warpper {
      color: #595961;
    }
  }
}
</style>
