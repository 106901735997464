<template>
  <div>
    <div
      v-if="name === 'subAccount' && checkPermission(['externaladmin:picture:statistics:merchant:search'])"
      class="header-warpper"
    >
      <el-form :inline="true" :model="query">
        <el-form-item label="账号：">
          <el-input
            @keyup.enter.native="toQuery"
            size="mini"
            clearable
            v-model="query.createByName"
            @clear="toQuery"
            placeholder="请输入账号名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期范围：" label-width="100px">
          <SaleDateTime key="1" ref="sale1" :time.sync="time" @change="toQuery"></SaleDateTime>
        </el-form-item>
        <el-form-item>
          <el-button class="ml10" size="mini" type="primary" @click="toQuery" icon="el-icon-search"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      class="header-warpper"
      v-if="name === 'picture' && checkPermission(['externaladmin:picture:statistics:gallery:search'])"
    >
      <el-form :inline="true" :model="query">
        <el-form-item label="图片名称：">
          <el-input
            @keyup.enter.native="toQuery"
            size="mini"
            clearable
            v-model="query.imageName"
            placeholder="请输入图片名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期范围：" label-width="100px">
          <SaleDateTime key="2" ref="sale2" :time.sync="time" @change="toQuery" :howDate="[]"></SaleDateTime>
        </el-form-item>
        <el-form-item>
          <el-button class="ml10" size="mini" type="primary" @click="toQuery" icon="el-icon-search"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <BatchOperation style="display: block">
      <checkAllData ref="checkAllData" @checkAllStatus="checkAllStatusChange" v-bind="$attrs"></checkAllData>
      <el-button v-if="permission.exportExcel" class="ml10" size="small" @click="$emit('exportTable')"
        >导出表格</el-button
      >
    </BatchOperation>
  </div>
</template>

<script>
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'
import checkAllData from '@/views/components/checkAllData3'
import { checkPermission } from '@/utils'

export default {
  mixins: [commonEheaderMixin],
  components: { SaleDateTime, checkAllData },
  props: {
    name: {
      type: String,
      default: 'subAccount'
    },
    permission: {
      default: () => ({})
    }
  },
  data() {
    return {
      selectionDataAllArr: [],
      url: '/externaladmin/systemService/currency/list',
      time: [],
      formField: [
        {
          prop: 'createTime'
        }
      ]
    }
  },
  methods: {
    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.checkAllData.getSelectionDataAllArr()
      this.selectionDataAllArr = tempArr
      return tempArr
    }
  }
}
</script>

<style lang="scss" scoped>
.header-warpper {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px;
  .saleDateTimeComponent {
    top: 6px;
  }
}
</style>
