<template>
  <div class="app-container statistics-warpper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="{ label, name } in validTabs" :label="label" :name="name" :key="name"> </el-tab-pane>
    </el-tabs>
    <myHeader
      ref="myHeader"
      :sup_this="sup_this"
      :tableData="data"
      :page="page"
      :size="size"
      :total="total"
      :isPageChange="isPageChange"
      :setIsPageChangeFalse="setIsPageChangeFalse"
      :selectionData="selectedData"
      :getAllData="getAllData"
      :getElTableInstance="getElTableInstance"
      :name="activeName"
      :query="query"
      :permission="curTab.permission"
      @exportTable="exportTable"
    />
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      :hideOnSinglePage="false"
      @refreshTableEventFun="refreshTableEventFun"
    />
    <CommonTable
      :selection="true"
      ref="commonRef"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      @selection-change="handleSelectionChange"
    >
      <template #imageSlot="{ scoped: row }">
        <color-text-Btn @click="handleImage(row)">{{ row.imageCount }} </color-text-Btn>
      </template>
      <template #productSlot="{ scoped: row }">
        <div class="flex-center">
          <el-popover placement="right" trigger="hover" :visible-arrow="false">
            <div slot="reference">
              <defaultImg :src="aLiCompressPicByUrl(row.imagePath, 48)"></defaultImg>
              <!-- <el-image
                style="width: 48px; height: 48px; border: 1px solid #dcdeeo; margin-right: 15px"
                fit="contain"
                :src="row.imagePath"
              ></el-image> -->
            </div>
            <div style="height: 40px; line-height: 32px; background: #fff">
              {{ row.imageName }}
            </div>
            <defaultImg :src="aLiCompressPicByUrl(row.imagePath, 450)"></defaultImg>
            <!-- <el-image fit="contain" :src="row.imagePath" style="width: 450px; height: 450px" :z-index="9999">
            </el-image> -->
          </el-popover>
          <span class="ml10">{{ row.imageName }}</span>
        </div>
      </template>
      <template #designedProductSlot="{ scoped: row }">
        <color-text-Btn @click="handleDesignedProduct(row)">{{ row.productCount }} </color-text-Btn>
      </template>
      <template #quantitySlot="{ scoped: row }">
        <color-text-Btn @click="handleQuantity(row)">{{ row.orderCount }} </color-text-Btn>
      </template>
      <template #importTimeSlot="{ scoped: row }">{{ parseTime(row.imageDate) }} </template>
    </CommonTable>
    <operationDialog
      :sup_this="sup_this"
      v-if="visible"
      :currentData="currentData"
      :visible.sync="visible"
      :type="type"
    />
    <PaginationBar
      :hideOnSinglePage="false"
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
  </div>
</template>

<script>
import myHeader from './module/myHeader'
import operationDialog from './module/operationDialog'
import initDataMixin from '@/mixins/initData'
import { subAccountCols, imageCols } from './module/cols'
import { checkPermission, parseTime } from '@/utils'
import { initData } from '@/api/data'
import { exportMerchantData, exportImageData } from '@/api/subAccount'
import saveAs from 'jszip/vendor/FileSaver.js'
import { tableOption } from '@/views/fedex/hadFedex/const'

export default {
  components: { myHeader, operationDialog },
  mixins: [initDataMixin],
  data() {
    return {
      activeName: 'subAccount',
      sup_this: this,
      cols: subAccountCols,
      url: '/externaladmin/reportService/misImageData/list',
      tabsList: [
        {
          label: '设计师',
          name: 'subAccount',
          valid: checkPermission(['externaladmin:picture:statistics:merchant']),
          permission: {
            exportExcel: checkPermission(['externaladmin:picture:statistics:merchant:export'])
          }
        },
        {
          label: '图片',
          name: 'picture',
          valid: checkPermission(['externaladmin:picture:statistics:gallery']),
          permission: {
            exportExcel: checkPermission(['externaladmin:picture:statistics:gallery:export'])
          }
        }
      ],
      showDesignedPicture: false,
      type: 'image',
      visible: false,
      currentData: {},
      sup_this: this
    }
  },
  computed: {
    curTab({ tabsList, activeName }) {
      return tabsList.find(({ name }) => name === activeName)
    },
    validTabs({ tabsList }) {
      return tabsList.filter(({ valid }) => valid) || []
    }
  },
  methods: {
    parseTime,
    initCallBack(res, postData) {
      let initCallBack = this.$refs.myHeader.$refs.checkAllData.initCallBack
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
    },
    handleType(type, data) {
      this.type = type
      this.currentData = data
      this.visible = true
    },
    handleImage(data) {
      this.handleType('image', data)
    },
    handleDesignedProduct(data) {
      this.handleType('designed', data)
    },
    handleQuantity(data) {
      this.handleType('output', data)
    },
    handleClick() {
      this.$refs.myHeader.time = []
      const queryList = ['endCreateTime', 'startCreateTime']
      queryList.map((item) => {
        this.query[item] = undefined
      })
      this.cols = this.activeName === 'subAccount' ? subAccountCols : imageCols
      this.url =
        this.activeName === 'subAccount'
          ? '/externaladmin/reportService/misImageData/list'
          : '/externaladmin/reportService/misImageDailyData/list'
      this.init()
    },
    async getAllData() {
      let res = await awaitResolve(
        initData(this.url, this.method, {
          ...this.params,
          isReturnRelatedData: 0,
          isReturnWaybillChangeRecord: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res
    },
    getElTableInstance() {
      return this.$refs.commonRef.$refs.table
    },
    async exportTable() {
      let res
      let str = ''
      let selectionData = await this.$refs.myHeader.getSelectionDataAllArr()
      let d = new Date()
      str += d.getFullYear() + '年'
      str += d.getMonth() + 1 + '月'
      str += d.getDate() + '日'
      const idList = selectionData?.map(({ id }) => id)
      const { startCreateTime, endCreateTime } = this.query
      const data = {
        idList
      }
      const time = {
        startCreateTime,
        endCreateTime
      }
      if (this.query.startCreateTime) Object.assign(data, time)
      if (selectionData.length == 0) {
        this.$message.warning('请先选择数据再导出表格')
        return
      } else if (this.activeName === 'subAccount') {
        res = await awaitFormResolve(exportMerchantData(data))
      } else {
        res = await awaitFormResolve(exportImageData(data))
      }
      if (!res) return this.$message.warning('导出失败')
      const message = this.activeName === 'subAccount' ? `${str}设计师图片统计` : `${str}图片统计`
      saveAs(res, message)
      this.init()
      this.$message.success('导出成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-warpper {
  display: flex;
  flex-direction: column;
  ::v-deep {
    .el-tabs__header {
      border-bottom: none;
      .el-tabs__nav {
        border-radius: 0px;
      }
    }
    .el-tabs__item {
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
      width: 135px;
      transition: none;
      font-size: 12px;
      background: #f7f8fa;
      color: #606266;
    }
    .el-tabs__item:hover {
      color: #333;
    }
    .el-tabs__item.is-active {
      border-bottom: 2px solid #3841db;
      color: #3841db;
    }
  }
}
</style>
